<template>
  <div>
    <form v-on:submit.prevent="save" class="column max" id="callback">
      <b-field label="ID" v-if="callback.id" horizontal>{{ callback.id }}</b-field>

      <b-field label="Created at" v-if="callback.created_at" horizontal>{{
        callback.created_at | moment('YYYY.MM.DD H:mm:ss')
      }}</b-field>

      <b-field label="Updated at" v-if="callback.updated_at" horizontal>{{
        callback.updated_at | moment('YYYY.MM.DD H:mm:ss')
      }}</b-field>

      <b-field label="Name" horizontal>
        <b-input v-model="callback.name" required type="text"></b-input>
      </b-field>

      <b-field label="Enabled" horizontal>
        <b-checkbox v-model="callback.enabled">{{ callback.enabled ? 'Yes' : 'No' }}</b-checkbox>
      </b-field>

      <b-field label="Note" horizontal>
        <b-input type="textarea" v-model="callback.note" rows="2"></b-input>
      </b-field>

      <b-field label="Method" horizontal>
        <b-select placeholder="Select a method" v-model="callback.method">
          <option value="POST">POST</option>
          <option value="GET">GET</option>
          <option value="PUT">PUT</option>
          <option value="PATCH">PATCH</option>
        </b-select>
      </b-field>

      <b-field label="URL Address" horizontal>
        <b-input v-model="callback.url" required type="url"></b-input>
      </b-field>

      <b-field label="Query Parameters" horizontal>
        <b-table :data="callback.params">
          <b-table-column field="name" label="Name" v-slot="props">
            <b-input v-model="props.row.name"></b-input>
          </b-table-column>

          <b-table-column field="value" label="Value" v-slot="props">
            <b-input v-model="props.row.value"></b-input>
          </b-table-column>

          <b-table-column field="action" v-slot="props">
            <a v-on:click="removeParamsItem(props.row)">
              <span class="icon has-text-info">
                <i class="mdi mdi-minus-circle" title="Remove"></i>
              </span>
            </a>
          </b-table-column>

          <template slot="empty">Empty</template>

          <template slot="footer">
            <b-button @click="addParamsItem" type="is-info" size="is-small">
              <i class="mdi mdi-plus-circle" title="Remove"></i> Add parameter
            </b-button>
          </template>
        </b-table>
      </b-field>

      <b-field label="HTTP Headers" horizontal>
        <b-table :data="callback.headers">
          <b-table-column field="name" label="Name" v-slot="props">
            <b-autocomplete v-model="props.row.name" :data="headersNames"></b-autocomplete>
          </b-table-column>

          <b-table-column field="value" label="Value" v-slot="props">
            <b-input v-model="props.row.value"></b-input>
          </b-table-column>

          <b-table-column field="action" v-slot="props">
            <a v-on:click="removeHeaderItem(props.row)">
              <span class="icon has-text-info">
                <i class="mdi mdi-minus-circle" title="Remove"></i>
              </span>
            </a>
          </b-table-column>

          <template slot="empty">Empty</template>

          <template slot="footer">
            <b-button @click="addHeaderItem" type="is-info" size="is-small">
              <i class="mdi mdi-plus-circle" title="Remove"></i> Add header
            </b-button>
          </template>
        </b-table>
      </b-field>

      <div v-if="callback.method !== 'GET'">
        <b-field label="Content Type" horizontal>
          <b-select placeholder="Select a Content Type" v-model="callback.content_type">
            <option value="application/json">application/json</option>
            <option value="application/x-www-form-urlencoded">application/x-www-form-urlencoded</option>
            <option value="application/octet-stream">application/octet-stream</option>
          </b-select>
        </b-field>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Payload</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <b-input type="textarea" v-model="callback.payload"></b-input>
              </div>
              <p class="help">
                Leave this field empty if no further
                <a href="http://docs.jsonata.org/simple" target="_blank">JSONata</a> transformation is required (the
                payload will be passed as-is)
              </p>
            </div>
          </div>
        </div>

        <b-field label="" horizontal>
          <b-field label="Original message">
            <b-input type="textarea" v-model="message" rows="8"></b-input>
          </b-field>
          <b-field label="Transformed payload">
            <b-input type="textarea" v-model="payload" rows="8"></b-input>
          </b-field>
        </b-field>
      </div>
      <br />
      <b-field label horizontal>
        <div class="buttons">
          <b-button v-if="!callback.id" type="is-success" icon-left="plus-box" native-type="submit"
            >Add Callback</b-button
          >
          <b-button v-if="callback.id" type="is-success" icon-left="content-save" native-type="submit"
            >Save Callback</b-button
          >
          <b-button v-if="callback.id" type="is-danger" v-on:click="showDeleteDialog" icon-left="trash-can"
            >Delete Callback</b-button
          >
        </div>
      </b-field>
    </form>

    <div class="modal is-active" v-if="deleteDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeDeleteDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to delete this callback?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="deleteMe">Delete</button>
          <button class="button" v-on:click="closeDeleteDialog">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import jsonata from 'jsonata';
import Callback from '../models/Callback';
import Message from '../models/Message';

export default {
  name: 'callback',
  data() {
    return {
      callback: {
        enabled: true,
        url: 'https://',
        method: 'POST',
        params: [],
        headers: [],
        content_type: 'application/json',
        payload: '',
      },
      headersNames: ['Authorization'],
      deleteDialog: false,
      message: null,
    };
  },
  created() {
    this.fetch();
    if (this.$route.params.groupId) this.fetchMessage();
  },
  methods: {
    addHeaderItem() {
      this.callback.headers.push({ name: '', value: '' });
    },
    removeHeaderItem(item) {
      const index = this.callback.headers.indexOf(item);
      if (index !== -1) this.callback.headers.splice(index, 1);
    },
    addParamsItem() {
      this.callback.params.push({ name: '', value: '' });
    },
    removeParamsItem(item) {
      const index = this.callback.params.indexOf(item);
      if (index !== -1) this.callback.params.splice(index, 1);
    },
    async fetch() {
      if (!this.$route.params.id) return;
      const { data } = await Callback.getOne(this.$route.params.id);
      this.callback = data;
      if (this.message === null) {
        this.fetchMessage();
      }
    },
    async save() {
      if (this.callback.id) {
        const { data } = await Callback.update(this.callback);
        this.callback = data;
      } else {
        this.callback.group_id = this.$route.params.groupId;
        const { data } = await Callback.add(this.callback);
        this.callback = data;
        this.$router.push({ name: 'callback', params: { id: data.id } });
      }
      this.$toast.success('Callback was saved');
    },
    async deleteMe() {
      if (!this.deleteDialog) return;
      await Callback.delete(this.callback.id);
      this.closeDeleteDialog();
      this.$toast.success('Callback was deleted');
      this.$router.push({
        name: 'callbacks',
        params: { groupId: this.callback.group_id },
      });
    },
    async fetchMessage() {
      let data;
      if (this.$route.query.message) {
        const rest = await Message.getOne(this.$route.query.message);
        data = rest.data;
      } else {
        const rest = await this.$http.get('/v1/messages', {
          params: {
            sort_field: 'created_at',
            sort_order: 'desc',
            limit: 1,
            group_id: this.$route.params.groupId || this.callback.group_id,
          },
        });
        data = rest.data && rest.data.length === 1 ? rest.data[0] : '';
      }

      if (data.confirmed !== undefined) delete data.confirmed;
      if (data.callback) delete data.callback;
      this.message = JSON.stringify(data, null, 2);
    },
    showDeleteDialog(e) {
      e.preventDefault();
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = null;
    },
  },
  computed: {
    messageObject() {
      return JSON.parse(this.message);
    },
    payload() {
      if (this.callback.payload === '') return this.message;
      try {
        const expr = jsonata(this.callback.payload);
        return JSON.stringify(expr.evaluate(this.messageObject), null, 2);
      } catch (error) {
        return `ERROR: ${error.message}`;
      }
    },
  },
};
</script>
<style>
#callback .table {
  background-color: transparent !important;
}
</style>
